import { emit } from "process";

export const SITE_NAME = "Surcos";

export const LOGIN_URL = "/users/login";
export const LOGOUT_URL = "/users/logout";

export const LANGUAGES = [{ code: "es-ar", name: "Español" }];
export const LANGUAGE_CODE = "es-ar";
export const CURRENCY_CODE = "USD";
export const MAX_DECIMALS = 2;

export const TIMEZONE = -3;
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

export const TAX_ID_MASKS = new Map([
  [
    "AR",
    [
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  ],
  [
    "CO",
    [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/],
  ],
  [
    "UY",
    [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "0", "0", "1", /\d/],
  ],
  ["BO", [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]],
  ["PY", [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/]],
]);
